/**
 * Returns an object given an iterator that returns `[key, value]` pairs,
 * similar to `Object.fromEntries()`.
 * This function handles repeated keys yielded by `entries` as an array of values
 * for that key in the generated object.
 *
 * @example
 * ```typescript
 * const params = new URLSearchParams('key1=value1a&key1=value1b&key2=value2');
 * objectFromEntries(params.entries()); // { key1: ['value1a', 'value1b'], key2: 'value2' }
 * ```
 */
export const objectFromEntries = (
  entries: IterableIterator<[string, unknown]>
) => {
  const result: Record<string, unknown> = {};

  for (const [key, value] of entries) {
    if (!key) continue;
    if (Object.prototype.hasOwnProperty.call(result, key)) {
      const prevValues = (
        Array.isArray(result[key]) ? result[key] : [result[key]]
      ) as unknown[];
      result[key] = [...prevValues, value];
    } else {
      result[key] = value;
    }
  }

  return result;
};

export default objectFromEntries;
