
<v-card
  :light="lightTheme"
  v-if="show"
  class="d-flex justify-center pa-5 elevation-0"
  :height="height"
>
  <v-progress-circular
    v-if="showPercentage"
    :size="size"
    v-model="progress"
    :query="true"
    color="primary"
    light
  >
    {{ `${Number(progress).toFixed(2)} %` }}
  </v-progress-circular>
  <v-progress-circular v-else indeterminate :size="size" color="primary">
    <span v-if="message">{{ message }}</span>
  </v-progress-circular>
</v-card>
