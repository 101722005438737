<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-card
    :light="lightTheme"
    v-if="show"
    class="d-flex justify-center pa-5 elevation-0"
    :height="height"
  >
    <v-progress-circular
      v-if="showPercentage"
      :size="size"
      v-model="progress"
      :query="true"
      color="primary"
      light
    >
      {{ `${Number(progress).toFixed(2)} %` }}
    </v-progress-circular>
    <v-progress-circular v-else indeterminate :size="size" color="primary">
      <span v-if="message">{{ message }}</span>
    </v-progress-circular>
  </v-card>
</template>

<script>
export default {
  name: 'loader',
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    showPercentage: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Number,
      default: 0,
    },
    size: {
      type: Number,
      default: 100,
    },
    message: {
      type: String,
      default: 'Loading...',
    },
    height: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    lightTheme() {
      const { name } = this.$route;
      return name === 'Alerts' || name === 'Production' || name === 'Uptime';
    },
  },
};
</script>
